<style>
#report-table .table-hover>tbody>tr:hover{
  background-color: unset;
  background-image: linear-gradient(rgba(255, 107, 21, 0.315),rgba(255, 107, 21, 0.315));
}
#report-table .table-sticky-header {
  overflow-y: auto;
  height: 80vh;
}
#report-table .table-sticky-header thead th{
  position: sticky;
  top: 0px;
}
</style>

<template>
  <div class="row">
    <div class="col-12 mb-4">
      <div class="dataTable-top">
        <div class="row">
          <div class="col-12">
            <!--h2 class="mb-1 mt-1 text-white">Opportunity report</h2-->
          </div>
          <div class="col-12" v-show="totalRows > 0">
            <div class="dataTable-info mt-0 mb-2 text-white">
              <!-- Showing {{firstRecord}} to {{lastRecord}} of {{ totalRows }} entries -->
              {{$t('showingEntries', [firstRecord, lastRecord, totalRows])}}
            </div>
            <div class="dataTable-dropdown mt-2">
              <label class="d-inline-flex">
                <select v-model="rowsPerPage"
                  class="
                    dataTable-selector
                    form-select
                    text-primary
                    form-select-sm
                  "
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <div class="align-items-baseline">
                  <p class="text-white ms-1 mb-0 pt-1">/</p>
                </div>
                <div class="align-items-baseline">
                  <p class="text-white ms-1 mb-0 pt-1">{{$t('page')}}</p>
                </div>
                <!--div class="align-items-baseline"><p class="text-white ms-1 me-2 mb-0 pt-1 ">,</p></div>
									<div class="align-items-baseline"><p class="text-orange ms-1 me-2 mb-0 pt-1 ">Total:</p></div>
									<div class="align-items-baseline"><p class="text-orange ms-1 mb-0 pt-1 ">77</p></div-->
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      <div class="card" id="report-table">
        <div class="card-body p-3 border-radius-sm">
          <button
            class="
              btn btn-sm
              bg-gradient-primary
              mx-auto
              mt-sm-n4 mt-md-n4
              position-absolute
              end-2
            "
            type="button"
            :disabled="reportName === 'paymentFailureReport' ? isProcessing || totalRows == 0 || isExportProcessing : isProcessing || totalRows == 0"
            @click="download()"
            v-if="reportName === 'activepolicyReport' || reportName === 'renewalReport' || (reportName === 'inspectionReport' && isAuthorityAdmin() === true) || reportName === 'paymentFailureReport' || (reportName === 'cancellationReport' && isAuthorityAdmin() === true)"
          >            
            {{$t('download')}}
            <span class="spinner-border spinner-border-sm loading text-info" role="status" aria-hidden="true" v-if="reportName === 'paymentFailureReport' && isExportProcessing"></span>  
          </button>
          <div class="size2pk mb-md-n5 mt-5 text-right">
            <button
              id="size2z1"
              class="btn btn-sm bg-white-line text-xs py-1 px-3 mt-2 active"
              @click="size2z1();"
            >
              <i class="fa fa-text-height" aria-hidden="true"></i>
            </button>
            <button
              id="size2z2"
              class="btn btn-md bg-white-line text-sm py-1 px-3 mt-1"
              @click="size2z2();"
            >
              <i class="fa fa-text-height" aria-hidden="true"></i>
            </button>
            <button
              id="size2z3"
              class="btn btn bg-white-line py-1 px-3"
              @click="size2z3();"
            >
              <i class="fa fa-text-height" aria-hidden="true"></i>
            </button>
          </div>
          <h4 class="mb-3 mt-4 text-primary">{{ $t(reportName) }}</h4>
          <div class="mb-1 mt-1 table5pk table-sticky-header">
            <table
              class="table table-flush dataTable-table z1 table-hover"
              id="datatable-search"
            >
            <!-- tableHeader -->
              <thead class="text-primary" style="text-align: center;">
                <!-- Opportunity Report Header -->
                <tr v-if="reportName === 'opportunityReport'">
                  <th></th>
                  <th>
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('oppname')"
                      ><i :class="getSortingIconClass('oppname')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custName") }}
                    <a
                      href="javascript:void(0)"
                      id="custName-sort"
                      v-on:click="sort('custName')"
                      ><i :class="getSortingIconClass('custName')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custMobile") }}
                    <a
                      href="javascript:void(0)"
                      id="custMobile-sort"
                      v-on:click="sort('custMobile')"
                      ><i :class="getSortingIconClass('custMobile')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custVehicleplate") }}
                    <a
                      href="javascript:void(0)"
                      id="custVehicleplate-sort"
                      v-on:click="sort('custVehicleplate')"
                      ><i :class="getSortingIconClass('custVehicleplate')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("createdDate") }}
                    <a
                      href="javascript:void(0)"
                      id="created-date-sort"
                      v-on:click="sort('createddate')"
                      ><i :class="getSortingIconClass('createddate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("startDate") }}
                    <a
                      href="javascript:void(0)"
                      id="start-date-sort"
                      v-on:click="sort('startdate')"
                      ><i :class="getSortingIconClass('startdate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("product") }}
                    <a
                      href="javascript:void(0)"
                      id="product-type-sort"
                      v-on:click="sort('producttype')"
                      ><i :class="getSortingIconClass('producttype')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("productOption") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('productoption')"
                      ><i :class="getSortingIconClass('productoption')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("netPremiumIncludeVatAndStamp1")}} <br> {{$t("netPremiumIncludeVatAndStamp2")}}
                    <a
                      href="javascript:void(0)"
                      id="premium-sort"
                      v-on:click="sort('premium')"
                      ><i :class="getSortingIconClass('premium')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("stage") }}
                    <a
                      href="javascript:void(0)"
                      id="stage-name-sort"
                      v-on:click="sort('stagename')"
                      ><i :class="getSortingIconClass('stagename')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("owner")}}
                    <a
                      href="javascript:void(0)"
                      id="owner-sort"
                      v-on:click="sort('ownername')"
                      ><i :class="getSortingIconClass('ownername')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("lastModified")}}
                    <a
                      href="javascript:void(0)"
                      id="lastmodified-date-sort"
                      v-on:click="sort('lastmodifieddate')"
                      ><i :class="getSortingIconClass('lastmodifieddate')"></i
                    ></a>
                  </th>
                  <th v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{$t("provider")}}
                    <a href="javascript:void(0)" id="opp-agent-sort" v-on:click="sort('agent')">
                      <i :class="getSortingIconClass('agent')"></i></a>
                  </th>
                </tr>

                <!-- Opportunity Affiliate Report Header -->
                <tr v-else-if="reportName === 'opportunityAffiliateReport'">
                  <th>
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('oppname')"
                      ><i :class="getSortingIconClass('oppname')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("product") }}
                    <a
                      href="javascript:void(0)"
                      id="producttype-sort"
                      v-on:click="sort('producttype')"
                      ><i :class="getSortingIconClass('producttype')"></i
                    ></a>
                  </th>
                   <th>
                    {{ $t("startDate") }}
                    <a
                      href="javascript:void(0)"
                      id="startDate-sort"
                      v-on:click="sort('startdate')"
                      ><i :class="getSortingIconClass('startdate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("activatedDate") }}
                    <a
                      href="javascript:void(0)"
                      id="activatedDate-sort"
                      v-on:click="sort('activateddate')"
                      ><i :class="getSortingIconClass('activateddate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("grossPremium") }}
                    <a
                      href="javascript:void(0)"
                      id="grossPremium-sort"
                      v-on:click="sort('grosspremium')"
                      ><i :class="getSortingIconClass('grosspremium')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("accumulatedPremium") }}
                    <a
                      href="javascript:void(0)"
                      id="accumulatedPremium-sort"
                      v-on:click="sort('accumulatedpremium')"
                      ><i :class="getSortingIconClass('accumulatedpremium')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("collectedPremium") }}
                    <a
                      href="javascript:void(0)"
                      id="collectedPremium-sort"
                      v-on:click="sort('collectedpremium')"
                      ><i :class="getSortingIconClass('collectedpremium')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("paymentFrequency")}}
                    <a
                      href="javascript:void(0)"
                      id="paymentFrequency-sort"
                      v-on:click="sort('paymentFrequency')"
                      ><i :class="getSortingIconClass('paymentFrequency')"></i
                    ></a>
                  </th>
                </tr>

                <!-- Active Policy Report Header -->
                <tr v-if="reportName === 'activepolicyReport'">
                  <th style="width: 7%"></th>
                  <th style="width: 9%">
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('oppname')"
                      ><i :class="getSortingIconClass('oppname')"></i>
                    </a>
                  </th>
                  <th style="width: 11%">
                    {{ $t("custName") }}
                    <a
                      href="javascript:void(0)"
                      id="custName-sort"
                      v-on:click="sort('custName')"
                      ><i :class="getSortingIconClass('custName')"></i>
                    </a>
                  </th>
                  <th style="width: 6%">
                    {{ $t("custMobile") }}
                    <a
                      href="javascript:void(0)"
                      id="custMobile-sort"
                      v-on:click="sort('custMobile')"
                      ><i :class="getSortingIconClass('custMobile')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custVehicleplate") }}
                    <a
                      href="javascript:void(0)"
                      id="custVehicleplate-sort"
                      v-on:click="sort('custVehicleplate')"
                      ><i :class="getSortingIconClass('custVehicleplate')"></i>
                    </a>
                  </th>
                  <th style="width: 10%">
                    {{ $t("activatedDate") }}
                    <a
                      href="javascript:void(0)"
                      id="created-date-sort"
                      v-on:click="sort('activateddate')"
                      ><i :class="getSortingIconClass('activateddate')"></i
                    ></a>
                  </th>
                  <th style="width: 6%">
                    {{ $t("startDate") }}
                    <a
                      href="javascript:void(0)"
                      id="start-date-sort"
                      v-on:click="sort('startdate')"
                      ><i :class="getSortingIconClass('startdate')"></i
                    ></a>
                  </th>
                  <th style="width: 5%">
                    {{ $t("product") }}
                    <a
                      href="javascript:void(0)"
                      id="product-type-sort"
                      v-on:click="sort('producttype')"
                      ><i :class="getSortingIconClass('producttype')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("productOption") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('productoption')"
                      ><i :class="getSortingIconClass('productoption')"></i
                    ></a>
                  </th>
                  <th style="width: 8%">
                    {{$t("netPremiumIncludeVatAndStamp1")}} <br> {{$t("netPremiumIncludeVatAndStamp2")}}
                    <a
                      href="javascript:void(0)"
                      id="premium-sort"
                      v-on:click="sort('premium')"
                      ><i :class="getSortingIconClass('premium')"></i
                    ></a>
                  </th>
                  <th style="width: 7%">
                    {{ $t("payment") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('payment')"
                      ><i :class="getSortingIconClass('payment')"></i
                    ></a>
                  </th>
                  <th style="width: 7%">
                    {{ $t("paymentMethod") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('paymentMethod')"
                      ><i :class="getSortingIconClass('paymentMethod')"></i
                    ></a>
                  </th>
                  <th style="width: 7%">
                    {{ $t("ePolicydocument") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('ePolicydocument')"
                      ><i :class="getSortingIconClass('ePolicydocument')"></i
                    ></a>
                  </th>
                  <th v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{$t("provider")}}
                    <a href="javascript:void(0)" id="active-agent-sort" v-on:click="sort('agent')">
                      <i :class="getSortingIconClass('agent')"></i>
                    </a>
                  </th>
                </tr>

                <!-- Cancellation Report Header -->
                <tr v-if="reportName === 'cancellationReport'">
                  <th></th>
                  <th>
                    {{ $t("opportunity") }}
                    <a href="javascript:void(0)" id="oppname-sort" v-on:click="sort('oppname')" >
                      <i :class="getSortingIconClass('oppname')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custName") }}
                    <a href="javascript:void(0)" id="custName-sort" v-on:click="sort('custName')" >
                      <i :class="getSortingIconClass('custName')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custMobile") }}
                    <a href="javascript:void(0)" id="custMobile-sort" v-on:click="sort('custMobile')">
                      <i :class="getSortingIconClass('custMobile')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("product") }}
                    <a href="javascript:void(0)" id="product-type-sort" v-on:click="sort('producttype')">
                      <i :class="getSortingIconClass('producttype')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("productOption") }}
                    <a href="javascript:void(0)" id="plan-type-sort" v-on:click="sort('productoption')">
                      <i :class="getSortingIconClass('productoption')"></i>
                    </a>
                  </th>
                  <th>
                    {{$t("netPremiumIncludeVatAndStamp1")}} <br> {{$t("netPremiumIncludeVatAndStamp2")}}
                    <a href="javascript:void(0)" id="netPremiumVat-sort" v-on:click="sort('netPremiumVat')">
                      <i :class="getSortingIconClass('netPremiumVat')"></i>
                    </a>
                  </th>
                  <th>
                    {{$t("netPremiumUnpaidOrRefund1")}} <br> {{$t("netPremiumUnpaidOrRefund2")}}
                    <a href="javascript:void(0)" id="netPremiumUnpaid-sort" v-on:click="sort('netPremiumUnpaid')">
                      <i :class="getSortingIconClass('netPremiumUnpaid')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("payment") }}
                    <a href="javascript:void(0)" id="paymentFrequency-sort" v-on:click="sort('paymentFrequency')">
                      <i :class="getSortingIconClass('paymentFrequency')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("paymentMethod") }}
                    <a href="javascript:void(0)" id="paymentMethod-sort" v-on:click="sort('paymentMethod')">
                      <i :class="getSortingIconClass('paymentMethod')"></i>
                    </a>
                  </th>
                  <th>
                    {{$t("closedWonDate")}}
                    <a href="javascript:void(0)" id="closedWonDate-sort" v-on:click="sort('closedWonDate')">
                      <i :class="getSortingIconClass('closedWonDate')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("startDate") }}
                    <a href="javascript:void(0)" id="startDate-sort" v-on:click="sort('startdate')">
                      <i :class="getSortingIconClass('startdate')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("cancelledDate") }}
                    <a href="javascript:void(0)" id="cancelled-date-sort" v-on:click="sort('cancelleddate')">
                      <i :class="getSortingIconClass('cancelleddate')"></i>
                    </a>
                  </th>
                  <th v-if="Global.locale == 'th'">
                    {{ $t("cancellationReason") }}
                    <a href="javascript:void(0)" id="cancellation-reason-th-sort" v-on:click="sort('cancellationreasonTH')">
                      <i :class="getSortingIconClass('cancellationreasonTH')"></i>
                    </a>
                  </th>
                  <th v-else>
                    {{ $t("cancellationReason") }}
                    <a href="javascript:void(0)" id="cancellation-reason-en-sort" v-on:click="sort('cancellationreasonEN')">
                      <i :class="getSortingIconClass('cancellationreasonEN')"></i>
                    </a>
                  </th>
                  <th v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{$t("provider")}}
                    <a href="javascript:void(0)" id="cancellation-agent-sort" v-on:click="sort('agent')">
                      <i :class="getSortingIconClass('agent')"></i>
                    </a>
                  </th>
                </tr>

                <!-- Cancellation Affiliate Report Header -->
                <tr v-else-if="reportName === 'cancellationAffiliateReport'">
                  <th>
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('oppname')"
                      ><i :class="getSortingIconClass('oppname')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("cancelledDate") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('cancelleddate')"
                      ><i :class="getSortingIconClass('cancelleddate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("refundedPremium") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('refundedpremium')"
                      ><i :class="getSortingIconClass('refundedpremium')"></i
                    ></a>
                  </th>
                </tr>

                <!-- Renewal Report Header -->
                <tr v-else-if="reportName === 'renewalReport'">
                  <th></th>
                  <th>
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('renweoppname')"
                      ><i :class="getSortingIconClass('renweoppname')"></i
                    ></a>
                  </th>
                  <th scope="col">
                    {{ $t("custName") }}
                    <a
                      href="javascript:void(0)"
                      id="custName-sort"
                      v-on:click="sort('custName')"
                      ><i :class="getSortingIconClass('custName')"></i>
                    </a>
                  </th>
                  <th scope="col">
                    {{ $t("custMobile") }}
                    <a
                      href="javascript:void(0)"
                      id="custMobile-sort"
                      v-on:click="sort('custMobile')"
                      ><i :class="getSortingIconClass('custMobile')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("product") }}
                    <a
                      href="javascript:void(0)"
                      id="product-type-sort"
                      v-on:click="sort('producttype')"
                      ><i :class="getSortingIconClass('producttype')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("productOption") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('productoption')"
                      ><i :class="getSortingIconClass('productoption')"></i
                    ></a>
                  </th>
                  <th scope="col">
                    {{$t("netPremiumIncludeVatAndStamp1")}} <br> {{$t("netPremiumIncludeVatAndStamp2")}}
                    <a
                      href="javascript:void(0)"
                      id="premium-sort"
                      v-on:click="sort('premium')"
                      ><i :class="getSortingIconClass('premium')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("stage") }}
                    <a
                      href="javascript:void(0)"
                      id="stage-name-sort"
                      v-on:click="sort('renewstagename')"
                      ><i :class="getSortingIconClass('renewstagename')"></i
                    ></a>
                  </th>
                  <!-- <th>
                    {{ $t("owner") }}
                    <a
                      href="javascript:void(0)"
                      id="owner-name-sort"
                      v-on:click="sort('ownername')"
                      ><i :class="getSortingIconClass('ownername')"></i
                    ></a>
                  </th> -->
                  <th v-if="Global.authority === 'ADMIN'">
                    {{ $t("brokerName") }}
                    <a
                      href="javascript:void(0)"
                      id="broker-name-sort"
                      v-on:click="sort('brokername')"
                      ><i :class="getSortingIconClass('brokername')"></i
                    ></a>
                  </th>
                  <th v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{ $t("provider") }}
                    <a
                      href="javascript:void(0)"
                      id="agent-email-sort"
                      v-on:click="sort('agentemail')"
                      ><i :class="getSortingIconClass('agentemail')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("renewalDate") }}
                    <a
                      href="javascript:void(0)"
                      id="renewal-date-sort"
                      v-on:click="sort('renewaldate')"
                      ><i :class="getSortingIconClass('renewaldate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("expiryDate") }}
                    <a
                      href="javascript:void(0)"
                      id="expiry-date-sort"
                      v-on:click="sort('expirydate')"
                      ><i :class="getSortingIconClass('expirydate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("autoRenewal") }}
                    <a
                      href="javascript:void(0)"
                      id="renewal-sort"
                      v-on:click="sort('autorenewal')"
                      ><i :class="getSortingIconClass('autorenewal')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("labelComments") }}
                    <a
                      href="javascript:void(0)"
                      id="comments-sort"
                      v-on:click="sort('description')"
                      ><i :class="getSortingIconClass('description')"></i
                    ></a>
                  </th>
                </tr>
                
                <!-- Inspection Report Header -->
                <tr v-else-if="reportName === 'inspectionReport'">
                  <th></th>
                  <th>
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('oppname')"
                      ><i :class="getSortingIconClass('oppname')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("startDate") }}
                    <a
                      href="javascript:void(0)"
                      id="start-date-sort"
                      v-on:click="sort('startdate')"
                      ><i :class="getSortingIconClass('startdate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("product") }}
                    <a
                      href="javascript:void(0)"
                      id="product-type-sort"
                      v-on:click="sort('producttype')"
                      ><i :class="getSortingIconClass('producttype')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("productOption") }}
                    <a
                      href="javascript:void(0)"
                      id="plan-type-sort"
                      v-on:click="sort('plantype')"
                      ><i :class="getSortingIconClass('plantype')"></i
                    ></a>
                  </th>
                  <!-- <th>
                    {{ $t("stage") }}
                    <a href="javascript:void(0)" id="stage-name-sort" v-on:click="sort('stagename')">
                      <i :class="getSortingIconClass('stagename')"></i>
                    </a>
                  </th> -->
                  <th>
                    {{ $t("inspectionStatus") }}
                    <a
                      href="javascript:void(0)"
                      id="status-sort"
                      v-on:click="sort('inspectionstatus')"
                      ><i :class="getSortingIconClass('inspectionstatus')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("lastDateForInspection") }}
                    <a
                      href="javascript:void(0)"
                      id="last-date-for-inspection"
                      v-on:click="sort('lastdateforinspection')"
                      ><i :class="getSortingIconClass('lastdateforinspection')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("owner") }}
                    <a
                      href="javascript:void(0)"
                      id="owner-name-sort"
                      v-on:click="sort('ownername')"
                      ><i :class="getSortingIconClass('ownername')"></i
                    ></a>
                  </th>
                  <th v-if="Global.authority === 'ADMIN'">
                    {{ $t("brokerName") }}
                    <a
                      href="javascript:void(0)"
                      id="broker-name-sort"
                      v-on:click="sort('brokername')"
                      ><i :class="getSortingIconClass('brokername')"></i
                    ></a>
                  </th>
                  <th v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{ $t("provider") }}
                    <a
                      href="javascript:void(0)"
                      id="agent-email-sort"
                      v-on:click="sort('agentemail')"
                      ><i :class="getSortingIconClass('agentemail')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("inspectionTaskCreatedDate") }}
                    <a href="javascript:void(0)" id="inspection-tasked-created-date-sort" v-on:click="sort('inspectiontaskcreateddate')">
                      <i :class="getSortingIconClass('inspectiontaskcreateddate')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("createdDate") }}
                    <a
                      href="javascript:void(0)"
                      id="created-date-sort"
                      v-on:click="sort('createddate')"
                      ><i :class="getSortingIconClass('createddate')"></i
                    ></a>
                  </th>
                </tr>

                <!-- Payment Report Header -->
                <tr v-if="reportName === 'paymentFailureReport' ">
                  <th></th>
                  <th>
                    {{ $t("opportunity") }}
                    <a
                      href="javascript:void(0)"
                      id="oppname-sort"
                      v-on:click="sort('oppname')"
                      ><i :class="getSortingIconClass('oppname')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("custName") }}
                    <a
                      href="javascript:void(0)"
                      id="custName-sort"
                      v-on:click="sort('custName')"
                      ><i :class="getSortingIconClass('custName')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("custMobile") }}
                    <a
                      href="javascript:void(0)"
                      id="custMobile-sort"
                      v-on:click="sort('custMobile')"
                      ><i :class="getSortingIconClass('custMobile')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("startDate") }}
                    <a
                      href="javascript:void(0)"
                      id="start-date-sort"
                      v-on:click="sort('startdate')"
                      ><i :class="getSortingIconClass('startdate')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("product") }}
                    <a
                      href="javascript:void(0)"
                      id="product-type-sort"
                      v-on:click="sort('producttype')"
                      ><i :class="getSortingIconClass('producttype')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("paymentMethod") }}
                    <a
                      href="javascript:void(0)"
                      id="payment-method-sort"
                      v-on:click="sort('paymentMethod')"
                      ><i :class="getSortingIconClass('paymentMethod')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("paymentFrequency")}}
                    <a
                      href="javascript:void(0)"
                      id="payment-frequency-sort"
                      v-on:click="sort('paymentFrequency')"
                      ><i :class="getSortingIconClass('paymentFrequency')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("premiumPayable")}}
                    <a
                      href="javascript:void(0)"
                      id="premium-payable-sort"
                      v-on:click="sort('premiumPayable')"
                      ><i :class="getSortingIconClass('premiumPayable')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("paidToDate")}}
                    <a
                      href="javascript:void(0)"
                      id="paid-to-date-sort"
                      v-on:click="sort('paidToDate')"
                      ><i :class="getSortingIconClass('paidToDate')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("overduePremium")}}
                    <a
                      href="javascript:void(0)"
                      id="overdue-premium-sort"
                      v-on:click="sort('overduePremium')"
                      ><i :class="getSortingIconClass('overduePremium')"></i
                    ></a>
                  </th>
                  <!-- <th>
                    {{ $t("stage") }}
                    <a
                      href="javascript:void(0)"
                      id="stage-name-sort"
                      v-on:click="sort('stagename')"
                      ><i :class="getSortingIconClass('stagename')"></i
                    ></a>
                  </th> -->
                  <!-- <th>
                    {{ $t("owner") }}
                    <a
                      href="javascript:void(0)"
                      id="owner-name-sort"
                      v-on:click="sort('ownername')"
                      ><i :class="getSortingIconClass('ownername')"></i
                    ></a>
                  </th> -->
                  <!-- <th>
                    {{$t("activatedDate")}}
                    <a
                      href="javascript:void(0)"
                      id="activated-date-sort"
                      v-on:click="sort('activateddate')"
                      ><i :class="getSortingIconClass('activateddate')"></i
                    ></a>
                  </th> -->
                  <th>
                    {{$t("dueDate")}}
                    <a
                      href="javascript:void(0)"
                      id="payment-status"
                      v-on:click="sort('duedate')"
                      ><i :class="getSortingIconClass('duedate')"></i
                    ></a>
                  </th>
                  <th>
                    {{$t("paymentDate")}}
                    <a
                      href="javascript:void(0)"
                      id="payment-paymentDate"
                      v-on:click="sort('paymentDate')"
                      ><i :class="getSortingIconClass('paymentDate')"></i
                    ></a>
                  </th>
                   <th>
                    {{$t("cancellationDate")}}
                    <a
                      href="javascript:void(0)"
                      id="payment-cancellationDate"
                      v-on:click="sort('cancellationDate')"
                      ><i :class="getSortingIconClass('cancellationDate')"></i
                    ></a>
                  </th>
                  <th v-if="Global.authority === 'ADMIN'">
                    {{ $t("brokerName") }}
                    <a
                      href="javascript:void(0)"
                      id="broker-name-sort"
                      v-on:click="sort('brokername')"
                      ><i :class="getSortingIconClass('brokername')"></i
                    ></a>
                  </th>
                  <th v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{$t("provider")}}
                    <a href="javascript:void(0)" id="payment-agent-sort" v-on:click="sort('agent')">
                      <i :class="getSortingIconClass('agent')"></i>
                    </a>
                  </th>
                  <th>
                    {{ $t("status") }}
                    <a
                      href="javascript:void(0)"
                      id="status-sort"
                      v-on:click="sort('status')"
                      ><i :class="getSortingIconClass('status')"></i
                    ></a>
                  </th>
                  <!-- <th>
                    Amount
                    <a
                      href="javascript:void(0)"
                      id="payment-status"
                      v-on:click="sort('amount')"
                      ><i :class="getSortingIconClass('amount')"></i
                    ></a>
                  </th>
                  <th>
                    {{ $t("createdDate") }}
                    <a
                      href="javascript:void(0)"
                      id="created-date-sort"
                      v-on:click="sort('createddate')"
                      ><i :class="getSortingIconClass('createddate')"></i
                    ></a>
                  </th> -->
                </tr>
              </thead>
            <!-- endof tableHeader -->
            <!-- tableBody -->
              <tbody v-if="totalRows && !isProcessing">
                <!-- Opportunity Report Body -->
                <tr v-for="(opportunity, index) in opportunities" :key="'opportunity' + index">
                  <td style="text-align: center;">
                    <button
                      class="btn-sm btn"
                      :id="'detail-' + opportunity.oppname"
                      @click="$emit('getDetails', opportunity);getRetrieveURLsetGlobal(opportunity?.oppname, opportunity?.product_type);"
                    >
                      {{ $t("detailLink") }}
                    </button>
                  </td>
                  <td>{{ opportunity.oppname }}</td>
                  <td>{{ opportunity.cust_name }}</td>
                  <td>
                    <a :href="'tel:' + opportunity.cust_mobile" v-if="!isNullOrEmpty(opportunity?.cust_mobile)">{{ opportunity.cust_mobile }}</a>
                    <span v-else>-</span>
                  </td>
                  <td>{{ showNullWithDash(opportunity.car_plate_number__c) }}</td>
                  <td>{{ opportunity.createddate }}</td>
                  <td>{{showNullWithDash(opportunity.start_date)}}</td>
                  <td>{{ showNullWithDash(displaySpecifiedProductType(opportunity.product_type)) }}</td>
                  <td>{{ showNullWithDash(opportunity.product_option) }}</td>
                  <td>{{ !isNullOrEmpty(opportunity?.premium) ? formattPrice(opportunity?.premium,2) : showNullWithDash(opportunity?.premium)}}</td>
                  <td>{{ opportunity.stagename }}</td>
                  <td>{{ opportunity.ownername }}</td>
                  <td>{{ showNullWithDash(opportunity.lastmodifieddate) }}</td>
                  <!-- <td :class="{'text-success' : opportunity.status === 'Activated', 'text-orange' : opportunity.status === 'Cancelled', 'text-info' : opportunity.status === 'Draft'}">
                    {{ showNullWithDash(opportunity.status) }}
                  </td>
                  <td>{{ opportunity.ownername }}</td>
                  <td v-if="Global.authority === 'ADMIN'">
                    {{ opportunity.broker_name }}
                  </td>-->
                  <td v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">{{ opportunity.intermediary_rept_email }}</td>
                  <!--<td>
                    {{showNullWithDash(opportunity.transacted_date__c) }}
                  </td> -->
                </tr>

                <!-- Renewal Report Body -->
                <tr v-for="(renewal, index) in renewals" :key="'renewal' + index">
                  <td style="text-align: center;">
                    <button
                      class="btn-sm btn"
                      :id="'detail-' + renewal.renew_oppname"
                      @click="$emit('getDetails', renewal)"
                      data-selenium-name="card-detail-list"
                      v-bind:data-selenium-value="renewal.renew_oppname"
                    >
                      {{ $t("detailLink") }}
                    </button>
                  </td>
                  <td>{{ renewal.renew_oppname }}</td>
                  <td>{{ renewal?.customer_name }}</td>
                  <td>{{ showNullWithDash(renewal?.customer_mobile) }}</td>
                  <td style="text-align: center">
                    {{ displaySpecifiedProductType(renewal.product_type__c) }}
                  </td>
                  <td style="text-align: center">
                    <!-- {{ showNullWithDash(renewal.plan_type) }} -->
                    {{ showNullWithDash(renewal.product_option) }}
                  </td>
                  <td style="text-align: center">{{ !isNullOrEmpty(renewal?.renewal_premium) ? formattPrice(renewal?.renewal_premium,2) : showNullWithDash(renewal?.renewal_premium)}}</td>
                  <td>{{ renewal.renew_stagename }}</td>
                  <!-- <td>{{ renewal.ownername }}</td> -->
                  <td v-if="Global.authority === 'ADMIN'">
                    {{ renewal.broker_name }}
                  </td>
                  <td v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{ renewal.intermediary_rept_email }}
                  </td>
                  <td>{{ renewal.renewaldate }}</td>
                  <td>{{ renewal.expirydate }}</td>
                  <td style="text-align: center">{{ renewal.autorenewal }}</td>
                  <td style="text-align: center">{{ renewal.description }}</td>
                </tr>

                <!-- Inspection Report Body -->
                <tr v-for="(inspection, index) in inspections" :key="'inspection' + index">
                  <td style="text-align: center;">
                    <button
                      class="btn-sm btn"
                      :id="'detail-' + inspection.oppname"
                       @click="$emit('getDetails', inspection);getRetrieveURLsetGlobal(inspection?.oppname, inspection?.product_type__c);"
                    >
                      {{ $t("detailLink") }}
                    </button>
                  </td>
                  <td>{{ inspection.oppname }}</td>
                  <td>
                    {{showNullWithDash(inspection.start_date__c)}}
                  </td>
                  <td style="text-align: center">
                    {{ displaySpecifiedProductType(inspection.product_type__c) }}
                  </td>
                  <td style="text-align: center">
                    {{showNullWithDash(inspection?.plan_type ? $t("type") + inspection?.plan_type : inspection?.plan_type)}}
                  </td>
                  <!-- <td>{{ inspection.stagename }}</td> -->
                  <td :class="{'text-success' : inspection.inspection_status === 'Closed', 'text-orange' : inspection.inspection_status === 'Cancelled', 'text-info' : inspection.inspection_status === 'Open'}">
                    {{showNullWithDash(inspection.inspection_status === "Closed" ? "Approved" : inspection.inspection_status === "In Progress" ? "Pending" : inspection.inspection_status) }}
                  </td>
                  <td>
                    {{showNullWithDash(inspection.last_date_for_inspection)}}
                  </td>
                  <td>{{ inspection.ownername }}</td>
                  <td v-if="Global.authority === 'ADMIN'">
                    {{ inspection.broker_name }}
                  </td>
                  <td v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{ inspection.intermediary_rept_email }}
                  </td>
                  <td>{{ inspection?.inspection_task_created_date }}</td>
                  <td>{{ inspection.createddate }}</td>
                </tr>

                <!-- Payment Report Body -->
                <tr v-for="(paymentFailure, index) in paymentFailures" :key="'paymentFailure' + index">
                  <td style="text-align: center;">
                    <button
                      class="btn-sm btn"
                      :id="'detail-' + paymentFailure.oppname"
                      @click="$emit('getDetails', paymentFailure)"
                    >
                      {{ $t("detailLink") }}
                    </button>
                  </td>
                  <td>{{ paymentFailure.oppname }}</td>
                  <td>{{ paymentFailure.cust_name }}</td>
                  <td>
                    <a :href="'tel:' + paymentFailure.cust_mobile" v-if="!isNullOrEmpty(paymentFailure?.cust_mobile)">{{ paymentFailure.cust_mobile }}</a>
                    <span v-else>-</span>
                  </td>
                  <td>
                    {{showNullWithDash(paymentFailure.start_date__c) }}
                  </td>
                  <td style="text-align: center">
                    {{ displaySpecifiedProductType(paymentFailure.product_type__c) }}
                  </td>
                  <td style="text-align: center">
                    {{showNullWithDash(paymentFailure.payment_method)}}
                  </td>
                  <td>
                    {{displayPaymentPlan(paymentFailure.payment_frequency)}}
                  </td>
                  <td>
                    {{formattPrice(paymentFailure.premium,2)}}
                  </td>
                  <td>
                    {{formattPrice(paymentFailure.paid_to_date,2)}}
                  </td>
                  <td>
                    {{formattPrice(paymentFailure.overdue_premium,2)}}
                  </td>
                  <!-- <td>{{ paymentFailure.stagename }}</td> -->
                  <!-- <td>{{ paymentFailure.ownername }}</td> -->
                  <!-- <td>
                    {{showNullWithDash(paymentFailure.transacted_date__c)}}
                  </td> -->
                  <td>
                    {{showNullWithDash(paymentFailure.payment_due_date)}}
                  </td>
                  <td>
                    {{showNullWithDash(paymentFailure.payment_date__c)}}
                  </td>
                  <td>
                    {{showNullWithDash(paymentFailure.paid_until_hc__c)}}
                    <!-- it is cancellation date -->
                  </td>
                  <td v-if="Global.authority === 'ADMIN'">
                    {{ paymentFailure.broker_name }}
                  </td>
                  <td v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">
                    {{ paymentFailure.intermediary_rept_email }}
                  </td>
                  <td :class="{'text-success' : paymentFailure.status === 'Activated', 'text-orange' : paymentFailure.status === 'Cancelled', 'text-info' : paymentFailure.status === 'Draft'}">
                    {{ showNullWithDash(paymentFailure.status)}}
                  </td>
                  <!-- <td>
                    {{formattPrice(paymentFailure.payment_amount,2)}}
                  </td>
                  <td>{{ paymentFailure.createddate }}</td> -->
                </tr>

                <!-- Opportunity Affiliate Report Body -->
                <tr v-for="(opportunityAff, index) in opportunitiesAff"
                  :key="'opportunity-aff' + index" >
                  <td style="text-align: center;">{{ opportunityAff.oppname }}</td>
                  <td style="text-align: center;">{{ displaySpecifiedProductType(opportunityAff.product_type) }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(opportunityAff.start_date) }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(opportunityAff.activated_date) }}</td>
                  <td style="text-align: center;">{{ !isNullOrEmpty(opportunityAff?.gross_premium || opportunityAff.gross_premium == 0) ? formatPrice(opportunityAff.gross_premium,2) : showNullWithDash(opportunityAff.gross_premium) }}</td>
                  <td style="text-align: center;">{{ !isNullOrEmpty(opportunityAff?.accumulated_premium || opportunityAff.accumulated_premium == 0) ? formatPrice(opportunityAff.accumulated_premium,2) : showNullWithDash(opportunityAff.accumulated_premium) }}</td>
                  <td style="text-align: center;">{{ !isNullOrEmpty(opportunityAff?.collected_premium || opportunityAff.collected_premium == 0) ? formatPrice(opportunityAff.collected_premium,2) : showNullWithDash(opportunityAff.collected_premium) }}</td>
                  <td style="text-align: center;">{{ displayPaymentPlan(opportunityAff.paymentfrequency)}}</td>
                </tr>

                <!-- Cancellation Affiliate Report Body -->
                <tr v-for="(cancelledOppAff, index) in cancelledOppsAff"
                  :key="'cancel-opportunity-aff' + index" >
                  <td style="text-align: center;">{{ cancelledOppAff.oppname }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(cancelledOppAff.cancelleddate) }}</td>
                  <td style="text-align: center;">{{ (!isNullOrEmpty(cancelledOppAff?.refunded_premium) || cancelledOppAff?.refunded_premium == 0) ? formattPrice(cancelledOppAff.refunded_premium,2) : showNullWithDash(cancelledOppAff.refunded_premium) }}</td>
                </tr>

                <!-- Sales & Policy Report Body -->
                <tr v-for="(activepolicy, index) in activepolicies" :key="'activepolicy' + index">
                  <td style="text-align: center;">
                    <button
                      class="btn-sm btn"
                      :id="'detail-' + activepolicy.oppname"
                      @click="$emit('getDetails', activepolicy);"
                      data-selenium-name="card-detail-list"
                      v-bind:data-selenium-value="activepolicy.oppname"
                    >
                      {{ $t("detailLink") }}
                    </button>
                  </td>
                  <td style="text-align: center;">{{ activepolicy.oppname }}</td>
                  <td style="text-align: center;">{{ activepolicy.cust_name }}</td>
                  <td style="text-align: center;">
                    <a :href="'tel:' + activepolicy.cust_mobile" v-if="!isNullOrEmpty(activepolicy?.cust_mobile)">{{ activepolicy.cust_mobile }}</a>
                    <span v-else>-</span>
                  </td>
                  <td style="text-align: center;">{{ showNullWithDash(activepolicy.car_plate_number__c) }}</td>
                  <td style="text-align: center;">{{ activepolicy.activated_date }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(activepolicy.start_date)}}</td>
                  <td style="text-align: center;">{{ showNullWithDash(displaySpecifiedProductType(activepolicy.product_type)) }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(activepolicy.product_option) }}</td>
                  <td style="text-align: center;">{{ !isNullOrEmpty(activepolicy?.premium) ? formattPrice(activepolicy?.premium,2) : showNullWithDash(activepolicy?.premium)}}</td>
                  <td style="text-align: center;">{{ showNullWithDash(activepolicy.payment_frequency) }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(activepolicy.payment_method) }}</td>
                  <td style="text-align: center;">{{ showNullWithDash(activepolicy.epolicydocument) }}</td>
                  <td style="text-align: center;" v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">{{ activepolicy.intermediary_rept_email }}</td>
                </tr>

                <!-- Cancellation Report Body -->
                <tr v-for="(cancelledOpp, index) in cancelledOpps" :key="'cancel-opportunity' + index">
                  <td style="text-align: center;">
                    <button class="btn-sm btn" :id="'detail-' + cancelledOpp.oppname" @click="$emit('getDetails', cancelledOpp);getRetrieveURLsetGlobal(cancelledOpp?.oppname, cancelledOpp?.product_type);">
                      {{ $t("detailLink") }}
                    </button>
                  </td>
                  <td>{{ cancelledOpp.oppname }}</td>
                  <td>{{ cancelledOpp.cust_name }}</td>
                  <td>
                    <a :href="'tel:' + cancelledOpp.cust_mobile" v-if="!isNullOrEmpty(cancelledOpp?.cust_mobile)">{{ cancelledOpp.cust_mobile }}</a>
                    <span v-else>-</span>
                  </td>
                  <td>{{ showNullWithDash(displaySpecifiedProductType(cancelledOpp.product_type)) }}</td>
                  <td>{{ showNullWithDash(cancelledOpp.product_option) }}</td>
                  <td>{{ !isNullOrEmpty(cancelledOpp?.net_premiun_include_vat) ? formattPrice(cancelledOpp?.net_premiun_include_vat,2) : showNullWithDash(cancelledOpp?.net_premiun_include_vat)}}</td>
                  <td>{{ !isNullOrEmpty(cancelledOpp?.net_premium_unpaid) ? formattPrice(cancelledOpp?.net_premium_unpaid,2) : showNullWithDash(cancelledOpp?.net_premium_unpaid) }}</td>
                  <td>{{ displayPaymentPlan(cancelledOpp.payment_frequency) }}</td>
                  <td>{{ cancelledOpp.payment_method }}</td>
                  <td>{{ cancelledOpp.closed_won_date }}</td>
                  <td>{{ showNullWithDash(cancelledOpp.start_date)}}</td>
                  <td>{{ cancelledOpp.cancelleddate }}</td>
                  <td v-if="Global.locale == 'th'">{{ cancelledOpp.cancellation_reason_th }}</td>
                  <td v-else>{{ cancelledOpp.cancellation_reason_en }}</td>
                  <td v-if="Global.brokerDetails.isTbAdmin || Global.authority === 'ADMIN'">{{ cancelledOpp.intermediary_rept_email }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="isProcessing">
                <td colspan="100%">
                  <div class="d-flex justify-content-center" v-if="isProcessing" id="load2report">
                    <div class="spinner-border text-info m-5" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
              </tbody>

              <tr class="bg-white" v-else>
                <td colspan="13" align="center" >
                  <h5 class="mb-0 text-danger">No data!</h5>
                  <img :src="publicPath + 'img/empty2state.png'" alt="No data!" class="max-w100" />
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="totalRows > 0 && !isProcessing">
    <div class="col-12 mb-4 text-center">
      <pagination
        v-model="Global.searchConditions[reportName].currentPage"
        :records="totalRows"
        :per-page="Global.searchConditions[reportName].rowsPerPage"
        @paginate="$emit('search')"
        :options="paginateOptions"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3";
import Global from "@/stores/GlobalVariables.js";
import axios from "axios";
import mixin from "@/components/Mixin";

export default {
  mixins: [mixin],
  name: "ReportTable",
  components: { Pagination },
  emits: ["search", "getDetails"],
  data: function () {
    return {
      Global,
      rowsPerPage: "20",
      paginateOptions: {
        hideCount: true,
      },
      isExportProcessing: false
    };
  },
  props: {
    opportunities: Array,
    opportunitiesAff: Array,
    activepolicies: Array,
    renewals: Array,
    inspections: Array,
    paymentFailures: Array,    
    cancelledOpps: Array,
    cancelledOppsAff: Array,
    totalRows: Number,
    reportName: String,
    isProcessing: Boolean
  },
  created() {},
  methods: {
    getSortingIconClass: function (sortKey) {
      if(sortKey != Global.searchConditions[this.reportName].sortKey){
        return 'fa fa-sort fa_custom_disable';
      }else{
        return Global.searchConditions[this.reportName].sortDirection === "asc" ? 'fa fa-sort-up fa_custom_enable' : 'fa fa-sort-down fa_custom_enable';
      }
    },
    sort: function(sortKey){
      Global.searchConditions[this.reportName].sortDirection = (Global.searchConditions[this.reportName].sortDirection === "desc" ? "asc": "desc");
      Global.searchConditions[this.reportName].sortKey = sortKey;
      this.$emit("search");
    },
    async download(){
      try {
        var searchConditions = Object.assign({}, Global.searchConditions[this.reportName]), prefix, endpoint;

        if(this.reportName === "opportunityReport"){
          prefix = "Opportunity_Report_";
          endpoint = "/opportunitiesExport";
        }else if(this.reportName === "renewalReport"){
          searchConditions["renewDateFrom"] = searchConditions["dateFrom"];
          searchConditions["renewDateTo"] = searchConditions["dateTo"];
          delete searchConditions["dateFrom"];
          delete searchConditions["dateTo"];

          prefix = "Renewal_Report_";
          endpoint = "/renewalReport/export";
        }else if(this.reportName === "inspectionReport"){
          prefix = "Inspection_Report_";
          endpoint = "/inspectionReport/export"
        }else if(this.reportName === "paymentFailureReport"){
          this.isExportProcessing = true;
          prefix = "Payment_Report_";
          endpoint = "/paymentFailureReport/export"
        }else if(this.reportName === "activepolicyReport"){
          prefix = "Active_Policy_Report_";
          endpoint = "/activepoliciesExport";
        }else if(this.reportName === "cancellationReport"){
          prefix = "Cancellation_Report_";
          endpoint = "/cancellationReport/export"
        }

        var response = await axios.get(Global.apiUrl + endpoint, {
          params: searchConditions,
          responseType: "blob"
        });

        const url = URL.createObjectURL(new Blob([response.data], {
            type: "application/vnd.ms-excel"
        }));
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute("download", prefix + this.getCurrentDateTime() +".xlsx");
        document.body.appendChild(link);
        link.click();
        this.isExportProcessing = false;
      } catch (error) {
        if (error?.response?.status === 401) {
          this.$router.go();
        }
        console.error(error);
        this.isExportProcessing = false;
      }
    },
    size2z1: function(){
			document.getElementById("datatable-search").classList.add("z1"); 
			document.getElementById("datatable-search").classList.remove("z2"); 
			document.getElementById("datatable-search").classList.remove("z3"); 
			
			document.getElementById("size2z1").classList.add("active"); 
			document.getElementById("size2z2").classList.remove("active"); 
			document.getElementById("size2z3").classList.remove("active");
		},
    size2z2 : function(){
			document.getElementById("datatable-search").classList.add("z2"); 
			document.getElementById("datatable-search").classList.remove("z1"); 
			document.getElementById("datatable-search").classList.remove("z3"); 
			
			document.getElementById("size2z2").classList.add("active"); 
			document.getElementById("size2z1").classList.remove("active"); 
			document.getElementById("size2z3").classList.remove("active");
		},
    size2z3: function(){
			document.getElementById("datatable-search").classList.add("z3"); 
			document.getElementById("datatable-search").classList.remove("z2"); 
			document.getElementById("datatable-search").classList.remove("z1"); 
			
			document.getElementById("size2z3").classList.add("active"); 
			document.getElementById("size2z2").classList.remove("active"); 
			document.getElementById("size2z1").classList.remove("active");
		},
    isAuthorityAdmin(){
      return Global.authority === 'AGENT_ADMIN' || Global.authority === 'ADMIN' ? true : false;
    }
  },
  computed: {
    firstRecord: function(){
      return ((Global.searchConditions[this.reportName].currentPage - 1) * parseInt(this.rowsPerPage)) + 1;
    },
    lastRecord: function(){
      var lastRecord = Global.searchConditions[this.reportName].currentPage * parseInt(this.rowsPerPage)
      return lastRecord > this.totalRows ? this.totalRows : lastRecord;
    },
  },
  watch: {
    rowsPerPage: function(value){
      Global.searchConditions[this.reportName].currentPage = 1;
      Global.searchConditions[this.reportName].rowsPerPage = parseInt(value);
      this.$emit("search");
    },
  },
};
</script>

<i18n>
{
  "en": {
    "createdDate": "Created Date",
    "opportunity": "Opportunity",
    "startDate": "Policy Start Date",
    "product": "Product",
    "productOption": "Product Option",
    "stage": "Stage",
    "inspectionStatus": "Inspection Status",
    "status": "Status",
    "owner": "Owner",
    "provider": "Service Provider",
    "brokerName": "Broker Name",
    "detailLink": "Detail",
    "lastModified": "Last modified",
    "renewalDate": "Pending Renewal Date",
    "autoRenewal": "Auto renewal",
    "labelComments": "Comments",
    "expiryDate": "Expiry Date",
    "download": "Download Details",
    "activatedDate": "Activated date",
    "payment": "Payment",
    "showingEntries": "Showing {0} to {1} of {2} entries",
    "page": "Page",
    "fullPayment": "Full",
    "12monthsInstallment": "12 months installment",
    "10monthsInstallment": "10 months installment with deposit",
    "10monthsInstallmentNoDeposit": "10 months installment",
    "4monthsInstallment": "4 months installment",
    "paymentMethod": "Payment Method",
    "paymentFrequency": "Payment",
    "premiumPayable": "Premium Payable",
    "paidToDate": "Paid To Date",
    "paymentDate":"Payment Date",
    "cancellationDate": "Cancellation Date",
    "overduePremium": "Overdue Premium",
    "custName": "Policy Holder Name",
    "custMobile": "Mobile Number",
    "custVehicleplate": "Car Plate",
    "netPremiumIncludeVatAndStamp1": "Net Premium",
    "netPremiumIncludeVatAndStamp2": "(Including VAT & Stamp)",
    "closedWonDate": "Activated Date",
    "dueDate": "Due Date",
    "paidUntilDate": "Paid Until Date",
    "accumulatedPremium": "Accumulated Premium",
    "collectedPremium": "Collected Premium",
    "lastDateForInspection":"Last date for inspection",
    "cancelledDate": "Cancelled date",
    "cancellationReason": "Cancellation Reason",
    "refundedPremium": "Refunded Premium",
    "grossPremium": "Gross Premium",
    "type": "Type ",
    "inspectionTaskCreatedDate": "Inspection task created date",
    "netPremiumUnpaidOrRefund1": "Net Premium",
    "netPremiumUnpaidOrRefund2": "Unpaid or Refunded",
    "ePolicydocument": "E-Policy Document"
  },
  "th": {
    "createdDate": "วันที่สร้างใบเสนอ",
    "opportunity": "ใบเสนอราคา",
    "startDate": "วันที่กรมธรรม์เริ่มคุ้มครอง",
    "product": "ผลิตภัณฑ์",
    "productOption": "ตัวเลือกผลิตภัณฑ์",
    "stage": "สถานะใบเสนอ",
    "inspectionStatus": "สถานะการตรวจสภาพรถ",
    "status": "สถานะกรมธรรม์",
    "owner": "สร้างโดย",
    "provider": "ผู้ให้บริการ",
    "brokerName": "โบรกเกอร์",
    "detailLink": "รายละเอียด",
    "lastModified": "แก้ไขล่าสุด",
    "renewalDate": "กรมธรรม์รอต่ออายุ",
    "autoRenewal": "การต่ออายุอัตโนมัติ",
    "labelComments": "เพิ่มความเห็นของคุณ",
    "expiryDate": "วันหมดอายุกรมธรรม์",
    "download": "ดาวน์โหลดข้อมูล",
    "activatedDate": "วันที่ปิดการขาย",
    "payment": "การชำระเงิน",
    "showingEntries": "กำลังแสดง {0} ถึง {1} จาก {2} รายการ",
    "page": "หน้า",
    "fullPayment": "จ่ายเต็มจำนวน",
    "12monthsInstallment": "ผ่อน 12 งวด",
    "10monthsInstallment": "ผ่อน 10 งวด (มีมัดจำ)",
    "10monthsInstallmentNoDeposit": "ผ่อน 10 งวด",
    "4monthsInstallment": "ผ่อน 4 งวด",
    "paymentMethod": "วิธีการชำระเงิน",
    "paymentFrequency": "การชำระเงิน",
    "premiumPayable": "เบี้ยประกันภัยที่ต้องชำระ",
    "paidToDate": "ยอดที่ชำระมาแล้ว",
    "paymentDate":"วันที่ชำระเงิน",
    "cancellationDate": "วันที่กรมธรรม์ถูกยกเลิก",
    "overduePremium": "เบี้ยประกันค้างชำระ",
    "custName": "ชื่อเจ้าของกรมธรรม์",
    "custMobile": "เบอร์โทรศัพท์",
    "custVehicleplate": "ทะเบียน",
    "netPremiumIncludeVatAndStamp1": "เบี้ยประกันภัยสุทธิ",
    "netPremiumIncludeVatAndStamp2": "(รวมภาษีมูลค่าเพิ่มและตราประทับ)",
    "closedWonDate": "วันที่ปิดการขาย",
    "dueDate": "วันครบกำหนด",
    "paidUntilDate": "ชำระเบี้ยถึงวันที่",
    "accumulatedPremium": "เบี้ยประกันภัยสะสม",
    "collectedPremium": "ยอดที่เก็บได้",
    "lastDateForInspection":"วันสุดท้ายของการตรวจสภาพรถ",
    "cancelledDate": "วันที่ยกเลิกกรมธรรม์",
    "cancellationReason": "เหตุผลในการยกเลิกกรมธรรม์",
    "refundedPremium": "ยอดเงินคืน",
    "grossPremium": "เบี้ยประกันภัยรวม",
    "type": "ประเภท ",
    "inspectionTaskCreatedDate": "วันที่สร้างงานตรวจสภาพ",
    "netPremiumUnpaidOrRefund1": "เบี้ยประกันภัย",
    "netPremiumUnpaidOrRefund2": "ที่ค้างชำระหรือขอคืน",
    "ePolicydocument": "กรมธรรม์อิเล็กทรอนิกส์"
  },
  "zh": {
    "createdDate": "创建日期",
    "opportunity": "报价号码",
    "startDate": "保单起始日期",
    "product": "产品",
    "productOption": "产品选项",
    "stage": "状态",
    "inspectionStatus": "验车状态",
    "status": "状态",
    "owner": "持有人",
    "provider": "服务提供商",
    "brokerName": "经纪人姓名",
    "detailLink": "详情",
    "lastModified": "上次修改",
    "renewalDate": "待续定日期",
    "autoRenewal": "自动续订",
    "labelComments": "评论",
    "expiryDate": "保单到期日",
    "download": "下载",
    "activatedDate": "激活日期",
    "payment": "支付",
    "showingEntries": "Showing {0} to {1} of {2} entries",
    "page": "Page",
    "fullPayment": "Full",
    "12monthsInstallment": "12 months installment",
    "10monthsInstallment": "10 months installment with deposit",
    "10monthsInstallmentNoDeposit": "10 months installment",
    "4monthsInstallment": "4 months installment",
    "paymentMethod": "支付方式",
    "paymentFrequency": "付款",
    "premiumPayable": "应付保费",
    "paidToDate": "支付日期",
    "paymentDate":"支付日期",
    "cancellationDate": "Cancellation Date",
    "overduePremium": "逾期保费",
    "custName": "保单持有人名",
    "custMobile": "客户手机号码",
    "custVehicleplate": "车牌",
    "netPremiumIncludeVatAndStamp1": "净保费",
    "netPremiumIncludeVatAndStamp2": "(含增值税&税票)",
    "closedWonDate": "激活日期",
    "dueDate": "到期日",
    "paidUntilDate": "支付截止日",
    "accumulatedPremium": "已付保费",
    "collectedPremium": "应付保费",
    "lastDateForInspection":"最后验车日期",
    "cancelledDate": "取消保单日期",
    "cancellationReason": "取消保单原因",
    "refundedPremium": "Refunded Premium",
    "grossPremium": "总保费",
    "type": "Type ",
    "inspectionTaskCreatedDate": "验车任务创建日期",
    "netPremiumUnpaidOrRefund1": "未付或已退还",
    "netPremiumUnpaidOrRefund2": "净保费",
    "ePolicydocument": "电子保单"
  }
}
</i18n>